import React , {Component} from 'react';
import Reveal from 'react-reveal/Reveal'
class FooterTwo extends Component {
    render(){
        var {fClass} = this.props;
        let FooterData = this.props.FooterData;
        return(
            <footer className={`footer_area footer_area_four f_bg ${fClass}`}>
                <div className="footer_top">
                    <div className="container">
                        <div className="row">
                            {
                                FooterData.CompanyWidget.map(widget=>{
                                    return(
                                        <Reveal effect="fadeInUp" key={widget.id}>
                                            <div className="col-lg-3 col-md-6">
                                                <div className="f_widget company_widget wow fadeInLeft" data-wow-delay="0.2s">
                                                <a href="index.html" className="f-logo"><img src={require('../../img/logo.png')} alt=""/></a>
                                                    <div className="widget-wrap">
                                                        <p className="f_400 f_p f_size_15 mb-0 l_height34"><span>Email:</span> <a href="mailto:info@hemdahl.com" className="f_400">info@hemdahl.com</a></p>
                                                        <p className="f_400 f_p f_size_15 mb-0 l_height34"><span>Phone:</span> <a href="tel:+35391884048" className="f_400">+353 91 884048</a></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </Reveal>
                                    )
                                })
                            }
                            <div className="col-3">
                                <p>
                                <b>Ireland</b><br/>
                                Galway Technology Centre<br/>
                                Mervue Business Park<br/>
                                Galway H91 FF8W<br/>
                                Ireland</p>
                                <p><i className="fa fa-envelope"></i> info@hemdahl.com</p>
                                <p><i className="fa fa-phone"></i> +353 91 884048</p>
                                <br/>
                            </div>
                            <div className="col-3">
                                <p>
                                <b>Norway</b><br/>
                                Alfaset 3<br/>
                                Industrivei 6<br/>
                                0668 Oslo<br/>
                                Norway</p>
                                <p><i className="fa fa-envelope"></i> info@hemdahl.com</p>
                                <p><i className="fa fa-phone"></i> +47 23 14 20 90</p>
                                <br/>
                            </div>
                            <div className="col-3">
                                <p>
                                <b>USA</b><br/>
                                1451 West Avenue<br/>
                                Unit 5028<br/>
                                Bronx NY 10462<br/>
                                USA</p>
                                <p><i className="fa fa-envelope"></i> info@hemdahl.com</p>
                                <p><i className="fa fa-phone"></i> (347) 918-3982</p>
                                <br/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer_bottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-5 col-sm-6">
                                <p className="mb-0 f_400">{FooterData.copywrite}</p>
                            </div>
                            <div className="col-lg-4 col-md-3 col-sm-6">
                                <div className="f_social_icon_two text-center">
                                    {
                                        FooterData.socialIcon.map(item =>{
                                            return(
                                                <a href={item.url} key={item.id}><i className={item.icon}></i></a>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12">
                                <ul className="list-unstyled f_menu text-right">
                                    <li><a href="/terms">Terms of Use</a></li>
                                    <li><a href="/privacy">Privacy Policy</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}
export default FooterTwo;