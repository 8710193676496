import React, {Component} from 'react';

class Whitepaper extends Component {
    render(){
        var {teamImage, title, fileName}= this.props;
        return(
            <div className="whitepaper">
                <img src={require ("../../img/whitepapers/" + teamImage)} alt="team"/>
                <div className="team_content">
                    <h5>{title}</h5>
                </div>
                <div className="hover_content">
                    <div >
                        <div className="br"></div>
                        <a href={"/whitepapers/" + fileName}>
                            <i className={`ti-import`}></i>
                            <h3 className="f_p f_size_16 f_600">{'Download'}</h3>
                            <h5>{fileName}</h5>
                        </a>
                    </div>
                </div>
            </div>
        )
    }
}
export default Whitepaper;