import React, {Component} from 'react';

class Newsitem extends Component {
    render(){
        var {image, title, story}= this.props;
        return(
            <div className="col-5 p-0 border mx-2">
                <img src={require ("../../img/news/" + image)} width="100%" height="180px" alt="news"/>
                <div className="p-2">
                    <div className="d-flex justify-content-center">
                        <h5 style={{textAlign: 'center'}}>{title}</h5>
                    </div>
                    <hr/>
                    <div className="d-inline-flex p-2">
                        <p style={{textAlign: 'center'}}>{story}.</p>
                    </div>
                </div>
            </div>
        )
    }
}
export default Newsitem;