import React from 'react';
import CustomNavbar from '../components/CustomNavbar';
import DesignBanner from '../components/Banner/DesignBanner';
import News from '../components/News/News';
import Whitepapers from '../components/Whitepapers/Whitepapers';
import FooterTwo from '../components/Footer/FooterTwo';
import FooterData from '../components/Footer/FooterData';

export const Home = () => (
    <div className="body_wrapper">
        <CustomNavbar cClass="custom_container p0" hbtnClass="new_btn"/>
        <DesignBanner/>
        <Whitepapers/>
        <News/>
        <FooterTwo FooterData={FooterData}/>
    </div>
)