import React from 'react';
import CustomNavbar from '../components/CustomNavbar';
import TermsArea from '../components/TermsArea';
import FooterTwo from '../components/Footer/FooterTwo';
import FooterData from '../components/Footer/FooterData';

const Terms = () => {
    return(
        <div className="body_wrapper">
            <CustomNavbar cClass="custom_container p0" hbtnClass="new_btn"/>
            <TermsArea/>
            <FooterTwo FooterData={FooterData}/>
        </div>
    )
}
export default Terms;