import React from 'react';
import CustomNavbar from '../components/CustomNavbar';
import PrivacyForm from '../components/PrivacyForm';
import FooterTwo from '../components/Footer/FooterTwo';
import FooterData from '../components/Footer/FooterData';

const Privacy = () => {
    return(
        <div className="body_wrapper">
            <CustomNavbar cClass="custom_container p0" hbtnClass="new_btn"/>
            <PrivacyForm/>
            <FooterTwo FooterData={FooterData}/>
        </div>
    )
}
export default Privacy;