import React from 'react';

const PrivacyForm =()=>{
    return(
        <div id="one" class="container-fluid p-4 mt-5">
			<div class="inner p-2">
			<header class="align-center">
				<br/>
				<h4>Privacy Policy</h4>
			</header>
				<div class="box">
					<div class="content">
					<p>Hemdahl Limited (hereinafter referred to as “Hemdahl” “us” “we” or “our”) is committed to respecting your
						privacy and to complying with all applicable data protection and privacy laws, including GDPR. This
						Privacy Policy governs the manner in which Hemdahl collects, uses, maintains and discloses information
						collected from users (each, a “User”) of the <a href="https://www.hemdahl.com"> https://www.hemdahl.com</a> and
						<a href="https://gimli.hemdahl.com"> https://gimli.hemdahl.com</a> websites (“Sites”).
						This privacy policy applies to the Sites and all products and services offered by Hemdahl Ltd. </p>

						<h4>This policy is intended to help you understand: </h4>

						<ul>
						<li>What information we collect about you</li>
						<li>How we use information we collect</li>
						<li>How we protect the information we collect </li>
						<li>How long the information is retained </li>
						<li>How to access and control the information</li>
						<li>Other important privacy information </li>
						</ul>


						<h4>What information we collect</h4>

						<b>Personal information</b>
						<p>We may collect personal identification information from Users in a variety of ways, including, but not limited to, when Users visit our site, register on the site,
							subscribe to the online analytics service, respond to a survey, fill out a form, and in connection with other activities, services, features or resources we make available on our Sites. </p>
						<p>Our registration forms require Users to give us contact information that may include name, email address, address, phone number, and similar information.
							We may use customer contact information from the contact forms to send the user requested information about our company.
							Admin Users signing up for the online analytics service may be asked to provide credit card and billing information.
							Users may, however, visit our Sites anonymously. We will collect personal identification information from Users only if they voluntarily submit such information to us.
							Users can always refuse to supply personally identification information, except that it may prevent them from engaging in certain Site related activities. </p>


						<b>Non-Personal information</b>
						<p>We may collect non-personal identification information about Users whenever they interact with our Sites.
							Non-personal identification information may include the browser name, the type of computer and technical information about Users means of connection to our Sites,
							such as the operating system and the Internet service provider utilized and other similar information. </p>

						<b>Data collected from third-parties</b>
						<p>As part of our online analytics services, we may collect User data from third-party telematic systems and transport management systems,
							including personal information such as identification information, ELD/Tachograph data, historical driving data, etc.
							This will only occur with the express permission of the User’s employer, i.e. the company who employs the User to perform commercial driving activity on their behalf. </p>


						<b>Use of cookies</b>
						<p>Our Sites may use cookies to enhance your experience while using our Sites. Users may choose to set their web browser to refuse cookies, or to alert you when cookies are being sent.
							If you choose to have your browser refuse cookies, it is possible that some areas of our Sites will not function properly when you view them. </p>

						<h4>How we use collected information</h4>
						<p>Information will not be used, disclosed or processed in any manner incompatible with the purposes for which it was obtained.
							We are the sole owners of the information collected on this site. We never sell, share, or trade any of our customer information with other companies. </p>

						<h4>Purposes for which we hold your information:</h4>

						<ul>
						<li>To improve customer service - information you provide helps us respond to your customer service requests and support needs more efficiently.</li>
						<li>To communicate with you and provide you with marketing and product information.</li>
						<li>To personalise your visit to the Site.</li>
						<li>To improve our Sites - we may use feedback you provide to improve our products and services.</li>
						<li>To track your movement through the Sites (for marketing and advertising purposes).</li>
						<li>For the performance of any request by or contract with you.</li>
						<li>To analyse telematic data in order to assess product safety, quality, and product risk, and communicate alerts/recommendations based on that analysis. </li>
						<li>To analyse TMS data in order to assess product safety, quality, and product risk, and communicate alerts/recommendations based on that analysis.  </li>
						<li>Telematic data and TMS data may also be used in aggregated form to provide further insights into overall performance of product quality and safety.</li>
						</ul>

						<p>We use the Non-Personal information gathered from visitors to our website in an aggregate form to get a better understanding of where our visitors come from
							and to help us better design and organise our website. </p>

						<h4>How we protect your information</h4>
						<p>We take precautions to protect your information. We adopt appropriate data collection, storage and processing practices and security measures to protect against unauthorized access,
							 alteration, disclosure or destruction of your personal information, username, password, transaction information and data stored on our Sites.
							 While we strive to protect your information, we cannot ensure or warrant the security of such information.</p>

						<h4>How long do we keep information? </h4>
						<p>Information will be kept for no longer than is necessary for the primary purpose for which it was obtained. Information may be retained for a longer period if required by law.
							Some information that is not otherwise subject to retention for normal reasons may need to be retained because of circumstances such as litigation or government investigation.
							In general, data will be deleted as soon as it is no longer needed for the purpose for which it was collected.</p>

						<h4>How to access and control the information</h4>

						<b>How do I obtain a copy of the information?</b>
						<p>You are entitled to a copy of your information kept by us. Simply send a request using the details below</p>

						<b>How do I remove the information?</b>
						<p>You can also have your information deleted from our system by request. Simply send a request using the details below. </p>

						<b>How do we keep the information accurate and up-to-date?</b>
						<p>We make every effort to keep User data accurate and up-to-date. In the event that the information may not be up-to-date or accurate, Users may contact us to update their information.
							Simply send a request using the details below. Requests should be in writing and should be addressed to</p>

						<p>Data Protection Officer<br/>
						Hemdahl Limited<br/>
						Galway Technology Centre<br/>
						Mervue Business Park<br/>
						Galway<br/>
						Ireland. </p>

						<p>Please quote your name, address and your Hemdahl username. We will take all reasonable steps to confirm your identity before acting on any requests. </p>

						<h4>Other important privacy information</h4>

						<b>Online surveys</b>
						<p>We may conduct occasional online surveys via our website or via an email newsletter to better understand the needs and profile of our visitors.
							When we conduct a survey, we will let you know how we will use the information at the time that we collect the information from you.</p>

						<b>External links</b>
						<p>Our Site may contain links to other websites. Please note that when you click one of these links you are entering another website for which Hemdahl has no responsibility.
							We encourage you to read the privacy statements on all such sites as their policies may be different from ours. </p>

						<b>General</b>
						<p>By using these Sites, you signify your acceptance of this policy and terms of service. If you do not agree to this policy, please do not use our Sites.
							We reserve the right, at our discretion, to change, modify, add, or remove portions of this policy at any time.
							Your continued use of the Sites following the posting of changes to this policy will be deemed your acceptance of those changes. </p>

						<b>Data Breaches</b>
						<p>In the event of a notifiable data breach, we undertake to contact affected users within 72 hours, in accordance with GDPR regulations.</p>

						<h4>Contacting Us</h4>
						<p>If you have any questions about this Privacy Policy, the practices of this site, or your dealings with this site, please contact our Data Protection Officer (DPO) at <a href="mailto:dpo@hemdahl.com">dpo@hemdahl.com</a>.</p>

						<p>You can also contact us by writing to: </p>
						<p>Data Protection Officer<br/>
						Hemdahl Limited<br/>
						Galway Technology Centre<br/>
						Mervue Business Park<br/>
						Galway<br/>
						Ireland. </p>
				</div>
				</div>
			</div>
			</div>
    )
}

export default PrivacyForm;