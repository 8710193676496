import React from 'react';

const TermsArea =()=>{
    return(
        <div id="one" class="container-fluid p-4 mt-4">
			<br/>
				<div class="inner container-fluid p-8 mt-12">
				<header class="align-center">
					<br/>
					<h2>Terms and Conditions</h2>
				</header>
					<div>
						<div class="box">
							<div class="content">
									<dl>
										<dt>Terms and Conditions of Service</dt>
										<dd>
											<p>This website is operated by Hemdahl Ltd. Throughout the site, the terms “we”, “us” and “our” refer to Hemdahl Ltd.
												This document governs the terms under which Hemdahl shall provide services (“Services”) to its users (each, a “User”) and/or customers (each, a “Customer”) of the
												 <a href="https://www.hemdahl.com"> https://www.hemdahl.com</a> and <a href="https://gimli.hemdahl.com">https://gimli.hemdahl.com</a> websites (“Sites”), and as such may place binding obligations on both parties.
												Please read these Terms of Service carefully before accessing or using our Sites. By accessing or using any part of the Sites,
												you agree to be bound by these Terms of Service. If you do not agree to all the terms and conditions of this agreement, then you may not access the Sites
												or use any Services. </p>
										</dd>
										<dt>User Obligations</dt>
										<dd>
											<p>You must provide accurate and complete registration information any time you register to use our Services.
												You are responsible for the security of your passwords and for any use of your account. If you become aware of any unauthorized use of your password or of your account,
												you agree to notify Hemdahl immediately.</p>
            								<p>Your use of our Services must comply with all applicable laws, regulations and ordinances, including any laws regarding the export of data or software.</p>

										</dd>
										<dt>Subscriptions</dt>
										<dd>
											<p>After applying for a subscription for the Services ("Subscription") through our online process, you will be given access to the Sites and the Services via a username and password.
												All applications are subject to acceptance by us, and we will confirm such acceptance to you by sending you an email that confirms that your Subscription has been completed (the "Subscription Confirmation").
												The agreement between us and you ("Agreement") will only be formed when we send you the Subscription Confirmation.</p>
           									 <p>A Subscription confers the right to use the Services as a Subscriber under a Paid Subscription, Gift Subscription, Free Trial, or other form of right and/or fee to access the Services.
           									 	Customer agrees to pay the appropriate monthly fee corresponding to the particular Services that you wish to access for the applicable Subscription Term ("Subscription Fee").
           									 	Any additional or new Services we provide to you throughout the duration of the Agreement are subject to these Terms and Conditions.
           									 	The monthly subscription can be cancelled by you at any time via the Site, or by contacting us directly via e-mail on <a href="mailto:support@hemdahl.com?Subject=Terms%20and%20Conditions" target="_top">support@hemdahl.com</a></p>

										</dd>
										<dt>Free Trials</dt>
										<dd>
											<p>A Free Trial allows the Customer to use the Services at no charge during the permissible Free Trial period and in accordance with any applicable terms, including, but not limited to,
												acceptance of these Terms and Conditions of Service. A Free Trial may or may not require the insertion of credit/debit card information.
												You can cancel the trial at any time. If you do not cancel the trial within the Free Trial period, you will automatically be enrolled in a monthly Subscription. </p>
										</dd>

										<dt>Payments and Charges</dt>
										<dd>
											<p>All recurring monthly subscription charges must be paid in advance either by Credit Card/Debit Card or by Direct Debit.</p>
											<p>Customer must pay for all charges incurred through Customer’s Hemdahl account whether or not incurred by Customer personally.</p>
											<p>Customer is responsible for all applicable sales, use, transfer or other taxes and all duties, whether international, national, state, or local,
												however designated, which are levied or imposed by reason of the transaction contemplated hereby, excluding, however, income taxes on profits which may be levied
												against the Company.</p>
											<p>Services may be available on the basis of different Price Plans and/or Packages with different rates of charges.
												Hemdahl may vary the charges at any time at Hemdahl's discretion. In the event that charges are varied Hemdahl will post notification of such changes on our Sites,
												and/or notify Customers by e-mail using the email address registered as active on their account.
												It shall be the responsibility of the Customer to ensure that this email address is up to date and Hemdahl shall not be held responsible for any communications
												not received where Customer has not registered a current/active email address on their Hemdahl account.
											</p>
										</dd>
										<dt>Personal Information</dt>
										<dd>
											<p>Your submission of personal information through our Site is governed by our <a href="privacy">Privacy Policy.</a></p>

										</dd>
										<dt>Third-Party Links</dt>
										<dd>
											<p>Hemdahl may provide hyperlinks or pointers to other websites or content or resources or email content maintained by third-parties.
												Hemdahl does not control third-party web sites or resources which are provided by companies or persons other than Hemdahl,
												and therefore Hemdahl is not responsible for any content on third-party sites linked from our Sites or which provide a link to our Sites.
												Use of any such linked sites is at the User’s own risk.
												References on our Sites to any names, marks, products or services of any third-parties or hypertext links to third-party sites or information are provided solely
												for your convenience and information, and do not constitute or imply an endorsement, sponsorship or recommendation of, or affiliation with the third-party or
												its products and services.
											</p>
										</dd>
										<dt>Copyright</dt>
										<dd>
											<p>All Hemdahl trademarks, names and logos are the proprietary marks of Hemdahl or its associated companies.
												Marks identifying third parties are owned or licensed by those third parties or their associated companies.
												There are a number of proprietary logos, service marks, trademarks, slogans and product designations found on our Sites.
												By making them available on our Sites, Hemdahl is not granting you a license to use them in any fashion.
												Access to our Sites does not confer upon you any license under any of Hemdahl or any third party’s intellectual property rights.
											</p>
										</dd>

										<dt>Limitation of Liability</dt>
										<dd>
											<p>Hemdahl will not be liable for any damages of any kind arising out of or relating to the use or the inability to use our web sites, content or links,
												including but not limited to damages caused by or related to errors, omissions, interruptions, defects, delay in operation or transmission, computer virus,
												 line failure, and all other direct, indirect, special, incidental, exemplary or consequential damages even if Hemdahl have been expressly advised of the
												 possibility of such damages. All information published on our web sites is provided “as is” without warranty of any kind, either express or implied, including,
												 but not limited to, the implied warranties of merchantability, fitness for a particular purpose, non-infringement, or other violation of rights.</p>

										</dd>
										<dt>Indemnification</dt>
										<dd>
											<p>You agree to hold harmless and indemnify Hemdahl, and its subsidiaries, affiliates, officers, agents, employees, advertisers, licensors, suppliers or partners
												(collectively “Hemdahl and Partners”) from and against any third party claim arising from or in any way related to (a) your breach of the Terms,
												(b) your use of the Hemdahl Services, (c) your violation of applicable laws, rules or regulations in connection with the Hemdahl Services,
												or (d) your Content or your Application, including any liability or expense arising from all claims, losses, damages (actual and consequential),
												suits, judgments, litigation costs and attorneys’ fees, of every kind and nature.</p>

										</dd>
										<dt>Intellectual Property</dt>
										<dd>
											<p>All rights, including copyright in Services and their content, belong to Hemdahl, or Hemdahl's Licensors. Hemdahl and they hereby reserve all rights.
												Nothing contained in this Agreement shall be construed as granting or conferring any rights to Customer by license, franchise, title, interest or otherwise in
												Hemdahl or any property of Hemdahl, including, without limitation, Hemdahl trade names, trademarks, service marks or proprietary information.
												You acknowledge and agree that Hemdahl (or Hemdahl's licensors) own all legal right, title and interest in and to the Hemdahl Services,
												including any intellectual property rights which relates to or subsists in the Hemdahl Services (whether those rights happen to be registered or not,
												and wherever in the world those rights may exist).
											</p>
										</dd>
										<dt>Changes to the Terms</dt>
										<dd>
											<p>Hemdahl may revise these terms of use for its web sites at any time without notice. By using these web sites you are agreeing to be bound by the then current version
												of these Terms and Conditions of Use</p>

										</dd>

										<dt>Governing Law</dt>
										<dd>
											<p>These Terms and Conditions of Service and any separate agreements whereby we provide you Services shall be governed by and construed in accordance with the laws of Ireland,
												 and you agree to submit to the exclusive jurisdiction of the Irish Courts in relation to any matter arising under or in connection with your use of these websites or our Services.</p>

										</dd>
										<dt>General</dt>
										<dd>
											<p>Information published on this website is subject to change without notice. Hemdahl may also make improvements and/or changes in the products and/or the services described
												 in this website at any time without notice.</p>

										</dd>
										<dt>Contacting Us</dt>
										<dd>
											<p>If you have any questions about these Terms and Conditions, the practices of this site, or your dealings with this site, please contact us at <a href="mailto:info@hemdahl.com?Subject=Terms%20and%20Conditions" target="_top">info@hemdahl.com</a>.</p>

										</dd>
									</dl>
							</div>
						</div>
					</div>
				</div>
			</div>
    )
}

export default TermsArea;