import React from 'react';
import Sectitle from '../Title/Sectitle';
import Whitepaper from '../Whitepapers/Whitepaper';
const Whitepapers =()=>{
    return(
        <section className="experts_team_area sec_pad">
            <div className="container">
                <Sectitle sClass="sec_title text-center mb_70" Title="Whitepapers" tClass="t_color3" TitleP="Hemdahl Whitepapers - click to download"/>
                <hr/>
                <div className="row d-flex justify-content-center mt-4">
                    <div className="col-lg-5 col-sm-6">
                        <Whitepaper teamImage="whitepaper_01.jpg" title="Predicting Product Temperatures" fileName="Hemdahl_WP_101_Predicting_Product_Temperatures.pdf"/>
                    </div>
                    <div className="col-lg-5 col-sm-6">
                        <Whitepaper teamImage="whitepaper_02.jpg" title="Pathogen Growth Prediction" fileName="Hemdahl_WP_102_Pathogen_Growth_Prediction.pdf"/>
                    </div>
                    <div className="col-lg-5 col-sm-6">
                        <Whitepaper teamImage="whitepaper_03.jpg" title="Shelf Life Prediction" fileName="Hemdahl_WP_103_Shelf_Life_Prediction.pdf"/>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default Whitepapers;