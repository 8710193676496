
import React, {Component} from 'react';
//import emailjs from '@emailjs/browser';

class Contacts extends Component {


    sendEmail(e) {
        e.preventDefault();
        const templateParams = Object.fromEntries(new FormData(e.target));

        /*emailjs
          .send('service_jei87fg', 'template_95oaugi', templateParams, {
              publicKey: '7zIvDVstRAFkB9668',
          })
          .then(
            () => {
              console.log('SUCCESS!');
              alert("Thank you for your message");
            },
            (error) => {
              console.log('FAILED...', error);
            },
        );*/
    };

    handleChange = e => {
        this.setState({[e.target.name]: e.target.value})
    }

    render(){
        return(
            <section className="contact_info_area sec_pad bg_color">
                <div className="container">
                    <div className="row">
                        <div className="contact_form col-lg-9">
                            <h2 className="f_p f_size_22 t_color3 f_600 l_height28 mb_40">Leave a Message</h2>
                             <form onSubmit={this.sendEmail} className="contact_form_box" method="post" id="contactForm">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="form-group text_box">
                                            <input type="text" id="name" name="name" placeholder="Your Name" onChange={this.handleChange}/>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group text_box">
                                            <input type="text" name="email" id="email" placeholder="Your Email" onChange={this.handleChange}/>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="form-group text_box">
                                            <input type="text" id="subject" name="subject" placeholder="Subject" onChange={this.handleChange}/>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="form-group text_box">
                                            <textarea onChange={this.handleChange} name="message" id="message" cols="30" rows="10" placeholder="Enter Your Message . . ."></textarea>
                                        </div>
                                    </div>
                                </div>
                                <button type="submit" className="btn_three">Send Message</button>
                            </form>
                            <div id="success">Your message succesfully sent!</div>
                            <div id="error">Opps! There is something wrong. Please try again</div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default Contacts;
