import React from 'react';
import Sectitle from '../Title/Sectitle';
import Newsitem from '../News/Newsitem';
const News =()=>{
    return(
        <section className="experts_team_area sec_pad" >
            <div className="container">
                <Sectitle sClass="sec_title text-center mb_70" Title="NEWS" tClass="t_color3" TitleP="Latest Hemdahl News"/>
                <hr/>
                <div className="row d-flex justify-content-center mt-4">
                    <Newsitem
                      image="news_01.jpg"
                      title="Hemdahl Receive Cool Logistics Award"
                      story="Hemdahl received the coveted 'Highly Commended' Award for Innovation in Perishable Logistics at the recent Cool Logistics Global Conference. The award recognises the hard work and innovation of the Hemdahl team in creating a solution to improve quality and safety of perishable cargo in the cold chain. Find out more about the Cool Logistics Global Conference"
                    />
                    <Newsitem
                      image="news_02.jpg"
                      title="Hemdahl Launch Pharma Analytics Solution"
                      story="Hemdahl have launched their new data analytics platform for companies who care about safety of pharmaceutical products during transit. By analyzing existing cold-chain data, Hemdahl provides unique and insightful analysis into the safety of pharma products, including vaccines."
                    />
                </div>
                <div className="row d-flex justify-content-center mt-4">
                    <Newsitem
                      image="news_03.jpg"
                      title="Hemdahl Launch Food Safety Solution"
                      story="Hemdahl have launched their new data analytics platform for companies who care about food safety. By analyzing existing cold-chain data, Hemdahl provides unique and insightful analysis into the safety of perishable foods."
                    />
                    <Newsitem
                      image="news_04.jpg"
                      title="Hemdahl Release Mobile App for Food Safety Professionals"
                      story="Hemdahl have released a new companion mobile app in both the Apple App Store and the Google Play Store. The Hemdahl Food Safety app is a great companion tool for anyone involved in shipping and storing perishable produce. It provides essential data on most common produce, including recommended setpoint, humidity, ethylene sensitivity, shelf life, and more. Download now from the App Store or the Play Store."
                    />
                </div>
            </div>
        </section>

    )
}
export default News;